/* eslint-disable react/no-unknown-property */
export const SwordBuckler = ({ size = "1em" }: { size?: number | string }) => (
  <svg
    width={size}
    stroke="currentColor"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 33V18H11V33H9Z" strokeWidth="2.75" strokeLinejoin="round" />
    <path d="M8 17V2H12V17H8Z" strokeWidth="2.75" strokeLinejoin="round" />
    <path d="M8 33V37H12V33H8Z" strokeWidth="2.75" strokeLinejoin="round" />
    <path d="M2 16V18H18V16H2Z" strokeWidth="2.75" strokeLinejoin="round" />
    <circle cx="22" cy="22" r="6" strokeWidth="2" />
    <circle cx="22" cy="22" r="6" strokeWidth="2" />
    <path
      d="M12 3.52924C14.9732 1.91614 18.3796 1 22 1C33.598 1 43 10.402 43 22C43 33.598 33.598 43 22 43C10.402 43 1 33.598 1 22C1 15.7805 3.70375 10.1925 8 6.34725"
      strokeWidth="2"
    />
  </svg>
);
