/* eslint-disable react/no-unknown-property */
export const Rapier = ({ size = "1em" }: { size?: number | string }) => (
  <svg
    width={size}
    stroke="currentColor"
    viewBox="0 0 29 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11 43V21H15V43H11Z" strokeWidth="2.75" strokeLinejoin="round" />
    <path d="M12 21V2H14V21H12Z" strokeWidth="2.75" strokeLinejoin="round" />
    <path d="M1 20.5H24" strokeWidth="2.75" strokeLinejoin="round" />
    <path
      d="M13 21C5 21 4 25 4 29C4 35 7.5 37.5 7.5 37.5M13 21C15.3333 20.8333 19.9 19.8 19.5 17C19 13.5 16.5 12 13 12C9.5 12 6.5 15 6.5 17C6.5 19 9 21 13 21Z"
      strokeWidth="2.75"
      strokeLinejoin="round"
    />
  </svg>
);
