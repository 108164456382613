import { Stack, Title } from "@mantine/core";
import { useTranslation } from "next-i18next";
import { listBoards } from "../../common/api";
import { Board } from "../../common/boards/board.schema";
import { i18Prefixes } from "../../common/i18";
import InfiniteList, { RenderFunction } from "../common/InfiniteList";
import Loading from "../common/Loading";
import BoardCard from "./BoardCard";
import { Sport } from "../../common/sports";

const FeaturedBoards = ({}: { sport?: Sport }) => {
  const { t } = useTranslation(["common", "home"]);
  const renderFunction = (board: Board) => {
    if (!board) return <Loading />;
    return <BoardCard key={board._id} board={board} />;
  };
  const doListBoards = async (offset: number, search?: string) => {
    return listBoards({
      offset,
      search,
      limit: 9,
      params: { featured: true },
      sort: "-lastMatch",
    });
  };
  return (
    <Stack>
      <Title order={2}>{t("featuredBoards", i18Prefixes.home)}</Title>
      <InfiniteList
        listFunction={doListBoards}
        renderFunction={renderFunction as RenderFunction}
        // queryParams={[]}
        queryKey={["featured"]}
      />
    </Stack>
  );
};
export default FeaturedBoards;
