import {
  Anchor,
  Group,
  GroupPosition,
  MantineSize,
  Stack,
  Text,
} from "@mantine/core";
import Link from "next/link";
import { CircleFlag } from "react-circle-flags";
import { useBoard, useMemberById } from "../../common/boards/board.hooks";
import { Member } from "../../common/members/member.schema";
import { getMemberLink } from "../../common/util";
import Hider from "../common/Hider";
import RecordAvatar from "../common/RecordAvatar";
import MemberName from "./MemberName";

const MemberDisplay = ({
  member: m,
  size,
  link,
  position,
  max,
  avatar,
  maxXS,
  club,
  flag,
  ...others
}: {
  member: Member | string;
  size?: MantineSize;
  link?: boolean;
  avatar?: boolean;
  club?: boolean;
  flag?: boolean;
  position?: GroupPosition;
  max?: number;
  maxXS?: number;
}) => {
  const { board } = useBoard();
  const { member } = useMemberById(m);
  // console.log("typeof member :", typeof member);
  if (max && !maxXS) maxXS = max;
  if (!member) return <></>;
  const innerContent = (
    <Group noWrap position={position}>
      {avatar && <RecordAvatar size={size} record={member} />}
      <Stack>
        <Group>
          <Hider smallerThan="xs">
            <Text className="name" size={size}>
              <MemberName member={member} max={max} />{" "}
            </Text>
          </Hider>
          <Hider largerThan="xs">
            <Text className="name" size={size}>
              <MemberName member={member} max={maxXS} />{" "}
            </Text>
          </Hider>
          {flag && member.country && (
            <CircleFlag
              countryCode={member.country.toLowerCase()}
              height={20}
            />
          )}
        </Group>
        {club && member.club && <Text size="xs">{member.club}</Text>}
      </Stack>
    </Group>
  );
  if (!board || !link) return <Group {...others}>{innerContent}</Group>;
  return (
    <Group {...others}>
      <Anchor
        component={Link}
        href={getMemberLink(board, member)}
        className="member-display"
      >
        {innerContent}
      </Anchor>
    </Group>
  );
};
export default MemberDisplay;
