import {
  createStyles,
  Overlay,
  Container,
  Title,
  Button,
  Text,
} from "@mantine/core";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import Link from "next/link";

const useStyles = createStyles((theme) => ({
  hero: {
    position: "relative",
  },

  container: {
    height: 700,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    paddingBottom: `calc(${theme.spacing.xl} * 6)`,
    zIndex: 1,
    position: "relative",
    paddingLeft: 100,
    paddingRight: 100,
    [theme.fn.smallerThan("sm")]: {
      height: 500,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },

  title: {
    color: theme.white,
    fontSize: 60,
    // fontWeight: 900,
    lineHeight: 1.1,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 40,
      lineHeight: 1.2,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: 28,
      lineHeight: 1.3,
    },
  },

  description: {
    color: theme.white,
    maxWidth: 600,

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
      fontSize: theme.fontSizes.sm,
    },
  },

  control: {
    marginTop: `calc(${theme.spacing.xl} * 1.5)`,

    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },
}));

export function HomeHero({
  title,
  body,
  image,
}: {
  title?: string;
  body?: string;
  image?: string;
}) {
  const { classes } = useStyles();
  const { t } = useTranslation("home");
  return (
    <div className={classes.hero}>
      <Image
        alt={title || t("title")}
        src={image || "/assets/images/hero.jpg"}
        quality={80}
        priority
        fill
        sizes="100vw"
        style={{
          objectFit: "cover",
        }}
      />
      <Overlay
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
        opacity={1}
        zIndex={0}
      />
      <Container className={classes.container}>
        <Title className={classes.title} order={2}>
          {title || t("title")}
        </Title>
        <Text className={classes.description} size="xl" mt="xl">
          {body || t("body")}
        </Text>
        <Button
          component={Link}
          href="/login?state=register"
          size="xl"
          radius="xl"
          className={classes.control}
        >
          {t("getStarted")}
        </Button>
      </Container>
    </div>
  );
}
