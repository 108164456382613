/* eslint-disable react/no-unknown-property */
export const Foil = ({ size = "1em" }: { size?: number | string }) => (
  <svg
    width={size}
    viewBox="0 0 26 45"
    stroke="currentColor"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 16C4.2 16 2 20.8889 2 22H24C24 20.8889 21.8 16 13 16Z"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.25 2L12 16H14L13.75 2H12.25Z"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 28V22.5H15V29C15 32 8.5 38 6.5 39.5C5.83333 40 5.5 41 3.5 41C1.5 41 0.999997 38 2.5 37C4 36 11 29.5 11 28Z"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 29C15.6667 30 16 28.5 20 31C22.1453 32.3408 23 33.5 23.5 35"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 34V43.5"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
