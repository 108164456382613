/* eslint-disable react/no-unknown-property */
export const Epee = ({ size = "1em" }: { size?: number | string }) => (
  <svg
    width={size}
    viewBox="0 0 30 45"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 14C4.1 14 2 22.1667 2 23H28C28 22.1667 24.9 14 14.5 14Z"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 2L13 14H17L16.5 2H13.5Z"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1113 29.0541V24H17V29.973C17 32.7297 10.6809 38.2432 8.73657 39.6216C8.08846 40.0811 7.7644 41 5.82006 41C3.87572 41 3.38964 38.2432 4.84789 37.3243C6.30615 36.4054 13.1113 30.4324 13.1113 29.0541Z"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 29C17.6667 30 18 28.5 22 31C24.1453 32.3408 25 33.5 25.5 35"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 34V43.5"
      strokeWidth="2.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
