/* eslint-disable react/no-unknown-property */
export const Longsword = ({ size = "1em" }: { size?: number | string }) => (
  <svg
    width={size}
    stroke="currentColor"
    viewBox="0 0 29 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 40V18H15V40H13Z" strokeWidth="2.75" strokeLinejoin="round" />
    <path d="M12 17V2H16V17H12Z" strokeWidth="2.75" strokeLinejoin="round" />
    <path d="M12 40V45H16V40H12Z" strokeWidth="2.75" strokeLinejoin="round" />
    <path d="M2 16V18H25V16H2Z" strokeWidth="2.75" strokeLinejoin="round" />
  </svg>
);
