import {
  Box,
  Container,
  createStyles,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
  Title,
  useMantineTheme,
} from "@mantine/core";
import {
  Icon,
  IconCoin,
  IconDeviceMobile,
  IconListNumbers,
  IconLock,
  IconSchool,
  IconStars,
  IconTrophy,
  IconUser,
  IconUsers,
} from "@tabler/icons-react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { Sport } from "../../common/sports";

export const data = [
  {
    icon: IconListNumbers,
    title: "featureEloTitle",
    description: "featureEloDescription",
  },
  {
    icon: IconTrophy,
    title: "featureTournamentTitle",
    description: "featureTournamentDescription",
  },
  {
    icon: IconUsers,
    title: "featureTeamTitle",
    description: "featureTeamDescription",
  },
  {
    icon: IconUser,
    title: "featurePlayerTitle",
    description: "featurePlayerDescription",
  },
  {
    icon: IconCoin,
    title: "featurePaymentTitle",
    description: "featurePaymentDescription",
  },
  {
    icon: IconSchool,
    title: "featureClassesTitle",
    description: "featureClassesDescription",
  },
  {
    icon: IconLock,
    title: "featureSecureTitle",
    description: "featureSecureDescription",
  },
  {
    icon: IconStars,
    title: "featureUSFATitle",
    description: "featureUSFADescription",
    sport: "fencing",
  },
  {
    icon: IconDeviceMobile,
    title: "featureMobileTitle",
    description: "featureMobileDescription",
  },
];

interface FeatureProps {
  icon: Icon;
  title: React.ReactNode;
  description: React.ReactNode;
}

export function Feature({ icon: Icon, title, description }: FeatureProps) {
  const theme = useMantineTheme();
  const { t } = useTranslation(["home"]);
  return (
    <div>
      <ThemeIcon variant="light" size={40} radius={40}>
        <Icon size={20} stroke={1.5} />
      </ThemeIcon>
      <Text style={{ marginTop: theme.spacing.sm, marginBottom: 7 }}>
        {t(title)}
      </Text>
      <Text size="sm" color="dimmed" style={{ lineHeight: 1.6 }}>
        {t(description)}
      </Text>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    marginBottom: theme.spacing.md,
    textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      fontSize: 28,
      textAlign: "left",
    },
  },

  description: {
    textAlign: "center",

    [theme.fn.smallerThan("sm")]: {
      textAlign: "left",
    },
  },
}));

export function Features({ sport }: { sport?: string }) {
  sport = sport || Sport.FENCING;
  const { theme } = useStyles();
  const features = data
    .filter((f) => !f.sport || f.sport === sport)
    .map((feature, index) => <Feature {...feature} key={index} />);
  const { t } = useTranslation(["home"]);

  return (
    <Stack spacing={0}>
      <SimpleGrid
        p={0}
        cols={2}
        spacing={`calc(${theme.spacing.xl} * 2)`}
        breakpoints={[{ maxWidth: 755, cols: 1, spacing: "xl" }]}
        style={{
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
        }}
      >
        <Container size={560} pt={20} px={40} pb={0}>
          <Title size={50} mb={20}>
            {t("secondTitle")}
          </Title>
          <Text size="md">{t("secondBody")}</Text>
        </Container>
        <Box ml={20} mt={30} style={{ textAlign: "right" }}>
          <Image
            src="/assets/images/glove-pool.png"
            alt="screenshot"
            width="500"
            height="500"
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </Box>
      </SimpleGrid>
      <Box
        style={{
          backgroundColor:
            theme.colorScheme === "dark" ? "#000" : theme.colors.gray[1],
        }}
      >
        <Container px={40} pb={80} size={860}>
          <SimpleGrid
            mt={60}
            cols={3}
            spacing={`calc(${theme.spacing.xl} * 2)`}
            breakpoints={[
              { maxWidth: 980, cols: 2, spacing: "xl" },
              { maxWidth: 755, cols: 1, spacing: "xl" },
            ]}
          >
            {features}
          </SimpleGrid>
        </Container>
      </Box>
    </Stack>
  );
}
