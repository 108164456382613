/* eslint-disable react/no-unknown-property */
export const Sabre = ({ size = "1em" }: { size?: number | string }) => (
  <svg
    width={size}
    stroke="currentColor"
    viewBox="0 0 29 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.26736 23.1506C2.44013 21.6364 4.26499 14 14.1581 14C24.0511 14 27 22.3034 27 23.1506C27 23.1506 24.1463 23.1506 10.8287 23.1506C3.84481 23.1506 5.67569 33.9663 8.45054 39.4184C10.9671 44.363 14.1581 43.9938 14.1581 43.9938C14.1581 43.9938 -0.110744 43.9938 2.26736 23.1506Z"
      strokeWidth="2.75"
      strokeLinejoin="round"
    />
    <path
      d="M14.25 2L14 14H16L15.75 2H14.25Z"
      strokeWidth="2.75"
      strokeLinejoin="round"
    />
    <path d="M13 44V24H17V44H13Z" strokeWidth="2.75" strokeLinejoin="round" />
  </svg>
);
