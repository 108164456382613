import { Member } from "../../common/members/member.schema";
import { getInitials } from "../../common/util";

const MemberName = ({ member, max }: { member: Member; max?: number }) => {
  let name: string = member.name;
  if (member.lastName) name = `${member.name} ${member.lastName}`;
  const nameSplit = name.trim().split(" ");
  const firstName = nameSplit[0];
  if (nameSplit.length > 1) {
    const lastName = nameSplit.slice(1).join(" ");
    name = `${firstName} ${lastName}`;
    if (max && name.length > max) {
      name = `${firstName} ${getInitials(lastName.split("-").join(" "))}`;
    }
    if (max && name.length > max) {
      name = `${getInitials(firstName)} ${lastName}`;
    }
    if (max && name.length > max) {
      name = `${getInitials(firstName)} ${getInitials(
        lastName.split("-").join(" ")
      )}`;
    }
  } else {
    name = firstName;
    if (max && name.length > max) {
      name = getInitials(name);
    }
  }
  return <>{name}</>;
};
export default MemberName;
