export const COMMON = "common";
export const AUTH = "auth";
export const NOT_FOUND = "notFound";
export const APP = "app";
export const BILLING = "billing";
export const CHECKOUT = "checkout";
export const HOME = "home";
export const PRIVACY = "privacy";
export const TERMS = "terms";
export const SUPPORT = "support";
export const TOURNAMENTS = "events";
export const PRICING = "pricing";
export const i18Prefixes = {
  common: { ns: COMMON },
  auth: { ns: AUTH },
  notFound: { ns: NOT_FOUND },
  app: { ns: APP },
  billing: { ns: BILLING },
  checkout: { ns: CHECKOUT },
  home: { ns: HOME },
  privacy: { ns: PRIVACY },
  terms: { ns: TERMS },
  support: { ns: SUPPORT },
  events: { ns: TOURNAMENTS },
  pricing: { ns: PRICING },
};
